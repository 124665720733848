<template>
  <Modal v-model="modal" sticky scrollable width="60%" :mask-closable="true" class="custom-modal"
    @on-cancel="modal =false">
    <template #header>
      <p class="title">驳回</p>
      <div class="modal-close" @click="modal =false">关闭 <span>ESC</span></div>
    </template>
    <div class="question">
      <h3>问题清单</h3>
      <ul>
        <li v-for="(item, i) in problem" :key="i">
          <div class="content">
            <div class="order">问题0{{ i + 1 }}</div>
            <div class="reason">
              <Input v-model="item.content" type="textarea" :rows="2" placeholder="请输入" />
            </div>
          </div>
          <div class="action">
            <span class="iconfont icon-jianshao" @click="handleDel(i)"></span>
            <span class="iconfont icon-zengjia" @click="handleAdd"></span>
          </div>
        </li>
      </ul>
    </div>
    <div class="question">
      <h3>其他说明</h3>
      <Input v-model="describe" type="textarea" :rows="8" placeholder="请输入" />
    </div>
    <div class="flex-center-center">
      <div class="btn btn-simple" @click="submitReason">确定</div>
    </div>
  </Modal>
</template>

<script setup>
import { ref, watch, inject } from "vue";
import bus from "@/utils/bus";
import { Message, Modal } from "view-ui-plus";
import { useRouter } from "vue-router"
import qs from "qs"
import { ElLoading } from "element-plus";
let axios = inject("axios");
let modal = ref(false);
let problem = ref([
  {
    content: "",
  },
]);

let router = useRouter();

let describe = ref("");
// let min = ref(1);
//删除问题
const handleDel = (i) => {
  if (problem.value.length > 1) {
    problem.value.splice(i, 1);
  }
};
//添加问题
const handleAdd = () => {
  problem.value.push({ content: "" });
};

const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });

const props = defineProps({
  id: String,
});
let dataId = ref("");
watch(
  () => props.id,
  (newVal, oldval) => {
    dataId.value = newVal;
  }
);
const submitReason = () => {
  //关闭提交弹框，打开操作记录弹框

  //判断一下是不是为空
  let isEmpty = false
  problem.value.forEach((item, index) => {
    if (item.content == '') {
      isEmpty = true
    }
  })

  if (isEmpty) {
    Message.error("驳回理由不能为空！");
  } else {
    let questions = problem.value.map((item) => {
      return item.content;
    });
    openFullScreen();
    let url = `/audit/audit`;
    let params = {
      dataId: dataId.value,
      other: describe.value,
      result: "驳回",
      questions: questions.join(","),
    };
    axios.post(url, qs.stringify(params)).then((res) => {
      Loading.value.close();
      if (res.data.code == 200) {
        Message.success("驳回理由提交成功");
        modal.value = false;
        router.push({ name: "examineList" });
        // bus.emit("openHistory", true);
      } else {
        Modal.error({
          title: '消息警告',
          content: res.data.message
        })
      }
    });
  }


};


const Loading = ref(null);
const openFullScreen = () => {
  Loading.value = ElLoading.service({
    lock: true,
    background: "rgba(255, 255,255, 0.5)",
  });
};
</script>

<style lang="scss" scoped>
:deep(.ivu-modal-mask) {
  background: rgba(55, 55, 55, 0);
}
</style>