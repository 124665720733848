<template>
  <Modal
    v-model="modal"
    sticky
    scrollable
    :mask-closable="true"
    width="60%"
    class="custom-modal"
    @on-cancel="handleCancel"
  >
    <template #header>
      <div class="title">操作记录</div>
      <div class="modal-close" @click="handleCancel">
        关闭
        <span>ESC</span>
      </div>
    </template>
    <div class="question">
      <Collapse class="custom-collapse" simple v-model="history">
        <Panel :name="item.id" v-for="(item, i) in historyList" :key="i">
          <span class="sub-title">{{ item.state }}</span>
          <span class="update">{{ item.updateDate }}</span>
          <template #content>
            <div class="question-item-content">
              <P>
                {{ item.recordMsg }}
              </P>
              <p v-if="item.content && item.content != null">
                修改内容：{{ item.content }}
              </p>
              <div class="questions" v-if="item.state == '驳回'">
                <label for="">问题清单</label>
                <div class="question-item">
                  <p v-for="(each, i) in item.questions" :key="i">
                    <span>{{ i + 1 }}</span>
                    <span>{{ each }}</span>
                  </p>
                </div>
              </div>
              <div class="questions" v-if="item.state == '驳回'">
                <label for="">其他说明</label>
                <div class="question-item">
                  <p>
                    {{
                      item.other != null && item.other != "" ? item.other : "无"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </Panel>
      </Collapse>
    </div>
  </Modal>
</template>
  
  <script setup>
import { ref, onMounted, inject, watch } from "vue";
import bus from "@/utils/bus";
let modal = ref(false);
let dataId = ref("");

let axios = inject("axios");

const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
// defineExpose({ modify });

let historyList = ref([]); //历史数据

let props = defineProps({
  id: String,
});

watch(
  () => props.id,
  (newVal) => {
    dataId.value = newVal;
    getHistoryList();
  }
);
let history = ref("");
bus.on("openHistory", (id) => {
  dataId.value = id;
  modify();
  getHistoryList();
});

//获取操作记录
const getHistoryList = () => {
  let url = `/audit/operaRecords?dataId=${dataId.value}`;
  axios.get(url).then((res) => {
    //  console.log(res);
    if (res.data.code == 200) {
      historyList.value = res.data.data;
      history.value = historyList.value[0].id;
    }
  });
};
const handleCancel = () => {
  modal.value = false;
  dataId.value = "";
  // bus.all.delete("openHistory");
};
onMounted(() => {
  if (dataId.value != "") {
    getHistoryList();
  }
});
</script>
  
<style lang="scss" scoped>
.custom-collapse {
  border: none;
  :deep(.ivu-collapse-header) {
    background-color: #fff;
    border-bottom: none !important;
    .update {
      float: right;
      padding-right: 20px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      letter-spacing: 0px;
      color: #555555;
    }
    .sub-title {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      letter-spacing: 0px;
      color: #111111;
    }
    .ivu-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  :deep(.ivu-collapse-item) {
    border-top: none;
  }
  :deep(.ivu-collapse-item:first-child) {
    margin-top: 10px;
  }
}
.question {
  //   border: none !important;
  padding-bottom: 50px;
  max-height: 60vh;
  overflow-y: auto;
}
.question-item-content {
  p {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #555555;
  }
}
.title {
  font-family: PingFangSC-Medium;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #111111;
}
.questions {
  display: flex;
  align-items: flex-start;
  width: 100%;
  > label {
    width: 120px;
    font-size: 14px;
    color: #555555;
    line-height: 30px;
    white-space: nowrap;
  }
  .question-item {
    width: calc(100% - 120px);
    // margin-left: 10px;
    p {
      font-size: 14px;
      color: #555555;
      line-height: 30px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      span:first-child {
        padding-right: 10px;
      }
      span:last-child {
        width: calc(100% - 20px);
        display: inline-block;
        word-break: break-all;
      }
    }
  }
}
.bold {
  text-decoration: underline;
  color: #3d55a8;
  padding: 0 3px;
  cursor: pointer;
}
</style>